<template>
  <div class="certificate">
    <div id="capture" className="view">
      <div class="down_capture_box">
        <div className="down_capture">
          <img class="certificate" src="@/assets/images/donate/certificate.png" alt="">
          <div class="capName" :style="`font-size:${data.capNameSize}px ;`">
            {{ data.capName }}
          </div>
          <div class="capNUm">
            {{ data.capNUm }}
          </div>
          <div class="captime">
            <div>{{ data.pay_time[0] }}</div>
            <div>{{ data.pay_time[1] }}</div>
            <div>{{ data.pay_time[2] }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="content">
      <!-- 顶部 -->
      <Header :titleName="data.titleName" :return="true" />
      <van-loading color="#888888" v-if="data.pageShow" vertical style="margin: 30vh 0;">加载中...</van-loading>
      <div v-else>
        <div class="captrueview">
          <div class="capheader">
            <img src="@/assets/images/donate/ti_img.png" alt="">
            捐款成功，您的证书已生成，长按图片保存
            <img src="@/assets/images/donate/ti_img.png" alt="">
          </div>

          <div>
            <img style="width: 100%;" :src="data.url" alt="">
          </div>




          <div class="cert_btns">
            <div @click="downImg">保存证书</div>
            <div class="active" @click="joinListBtn" v-if="data.showJia">加入卡包</div>
			<div class="active" v-if="!data.showJia">已加入卡包</div>
          </div>
        </div>
      </div>

    </div>


  </div>


</template>
<script setup>
import { reactive, onMounted } from "vue";
import html2canvas from 'html2canvas'
import Header from "../../components/Header.vue";
import { useRoute, useRouter } from 'vue-router'
import { Toast, Popup } from 'vant';
import Api from "../../utils/api";
import { jsToCallApp } from '../../utils/callApp1.0.0.js';
import api from "../../utils/api";


const route = useRoute()
const router = useRouter()
let data = reactive({
  capName: '耿先生',
  capNameSize: 18,
  capNUm: '30000000.00',
  pay_time: [],
  url: '',
  pageShow: true,
  uid:"",
  checkSetTimeOut:null,
  showJia:true,
  showJiaTxt:"加入卡包",
  id:0,
  titleName:'捐赠证书',
});

onMounted(() => {
  data.capName = route.query.tmpName;
  data.capNUm = route.query.amount;
  data.pay_time = filterTime(route.query.pay_time);
  data.id=route.query.id;
  setFontSize();
  setTimeout(() => {
    BtnLogo();
  }, 1000);
  console.log('route:' + data.id)
	//返回是否按钮可用
	Api.checkKa({id:data.id}).then(res => {
	  console.log('返回是否按钮可用:' + JSON.stringify(res))
	  if (res.status == 200) {
		  if( res.data.code==1){
			  data.showJia=true;
		  }else{
			  data.showJia=false ;
		  }
	  }
	});
  shareFn()

});

const shareFn = () => {
  const jsonString = {
    shareUrl:window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunctionShare('setupShareData', { shareData: JSON.stringify(jsonString) })
}

function openAppFunctionShare(type, jsonData) {
    const useTimeOut = setTimeout(() => {
      jsToCallApp(
        {
          type: type,
          ...jsonData,
        },
        (data) => {
          console.log(type, data)
        },
      )
      clearTimeout(useTimeOut);
    }, 300);
  }
//证书时间转化
const filterTime = (time) => {
  // 时间戳 
  // let timestamp = new Date(time).getTime()
  // console.log(timestamp)
  // 此处时间戳以毫秒为单位
  let date = new Date(parseInt(time) * 1000);
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  return [Year, Moth, Day]
};


const downImg = () => {
  // let a = document.createElement('a');
  // a.download = '证书';
  // a.href = data.url
  // a.click();
  downloadIamge(data.url,'证书')
};


function downloadIamge(imgsrc, name) {  //下载图片地址和图片名
  const image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    const a = document.createElement("a"); // 生成一个a元素
    const event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}



//打开卡包
const joinListBtn = () => {
	//如果已经存在did，直接添加卡包
	let localDid = localStorage.getItem('did');
	if (localDid != null) {
		addCardPackage(localDid);
		return;
	}
	//返回授权拼接字符串
	Api.getUid().then(res => {
	  console.log('授权拼接字符串:' + JSON.stringify(res))
	  if (res.status == 200) {
		  //授权码
		  const code = res.data.code;
		  console.log('授权码:' + code);
		  //随机数
		  data.uid = res.data.uid;
		openAppFunction('toOpenKbAuthGetAuthInfo',{
			authCode: code,
		});
	  }
	});
}

//轮询检查卡片是否上传完毕
var uidTimer;
function checkUid(){
	uidTimer = setInterval(function(){
		Api.getUidStat({uid:data.uid}).then(res => {
		  console.log('checkUid状态:' + JSON.stringify(res))
		  if (res.status == 200) {
			  const uidState = res.data.uid;
			  console.log('uidState:' + uidState);
			  if (uidState == "true") {
				  console.log('状态检查成功，开始加入卡包');
				  clearInterval(uidTimer);
				  if (res.data.did !='') {
					  addCardPackage(res.data.did);
				  } else {
					  Toast('获取用户did失败');
				  }
			  }
		  }
		})
	},1000);
}

//放入用户卡包
function addCardPackage(did) {
	let parmas = {};
	parmas['_did'] = did;
	parmas['name'] = data.capName;
	parmas['amount'] = data.capNUm;
	parmas['date'] = data.pay_time;
	parmas['id'] = data.id;
	//console.log('addCardPackage parmas\n');
	//console.log(parmas);
	Api.uploadCardPackage(parmas).then(res => {
	  console.log('addCardPackage:' + JSON.stringify(res))
	  if (res.status == 200) {
		  
		  console.log('res.data.code:' + res.data.code);
		  if (res.data.code == "1") {
			  localStorage.setItem('did',did);
			  data.showJia=false ;
			 
			  Toast('加入卡包成功');
		  }
	  }
	});
}

function openAppFunction(type,otherData) {
	const useTimeOut = setTimeout(() => {
		jsToCallApp(
			{
				type: type,
				...otherData
			},
			(backData) => {
				console.log(type, backData)
				if (-1 !== backData && backData) {
					const backResult = JSON.parse(backData);
					console.log('h5授权:' );
					console.log(backData);
					//授权成功
					if (backResult.code == "0") {
						console.log('开始轮询检查');
						checkUid();
					}else if (backResult.code == "-1") {
						Toast('授权进程错误');
					}else if (backResult.code == "-2") {
						Toast('用户拒绝授权');
					}else if (backResult.code == "-3") {
						Toast('用户没有对应的凭证');
					}
					//showContentDom.innerHTML = backData;
				}
			},
		)
		clearTimeout(useTimeOut);
	}, 300);
}

//base64str： base64地址（"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABNo..."），fileName：文件名("image.png")
function exportImage(base64str, fileName) {
  // 将base64格式的图片转换成Blob对象
  var arr = base64str.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // 将Blob对象转换成文件并下载到本地
  var blob = new Blob([u8arr], {
    type: mime
  });
  data.url = URL.createObjectURL(blob);
  data.pageShow = false
}


function setFontSize() {
  // data.capName = '宝鸡有一群怀揣着梦想的少年'
  // console.log(data.capName.length)
  // console.log(document.querySelector('.capName').clientWidth);
  // console.log(document.querySelector('.capName').scrollWidth);
  let yy = document.querySelector('.capName')
  setTimeout(() => {
    if (yy.scrollWidth > yy.clientWidth) {
      data.capNameSize = yy.clientWidth / data.capName.length
    }
  }, 1000);


}



const BtnLogo = () => {
  // console.log('上传logo')
  let dpi = window.devicePixelRatio || 2
  let options = {
    useCORS: true, //跨域
    ignoreElements: false,
    scale: dpi,
  }
  html2canvas(document.querySelector('#capture'), options).then(canvas => {
    let url = canvas.toDataURL('image/png') //转base64
    // document.querySelector('.captures').innerHTML = `<img src="${url}" alt="231231">`
    //let domimg = document.querySelector('img') //复制-扩展知识
    // console.log(url);
    // data.url = url
    exportImage(url, 'aaaa')
  })
}

</script>
<style lang='less' scoped>
#capture {
  position: fixed;
  left: 9999px;

  .down_capture_box {
    width: 100%;
    background-color: #fff;
    padding: .32rem;
  }

  .down_capture {
    width: 9.3867rem;
    position: relative;

    .capName {
      width: 4rem;
      // background-color: red;
      height: .7rem;
      font-weight: 600;
      position: absolute;
      top: 6.6rem;
      left: 3.1rem;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .captime {
      width: 4;
      // background-color: red;
      height: .7rem;
      font-weight: 600;
      position: absolute;
      top: 13.6rem;
      right: 2.05rem;
      font-size: 16px;
      text-align: center;
      display: flex;

      >div {
        margin-left: .75rem;
      }

    }

    .capNUm {
      width: 3.5rem;
      // background-color: red;
      height: .7rem;
      font-weight: 600;
      position: absolute;
      top: 8rem;
      left: .6rem;
      font-size: 18px;
      text-align: center;
    }
  }

  .certificate {
    width: 100%;
  }
}

.certificate {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;

  .content {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    padding-bottom: .4rem;
  }

}

.captrueview {


  .capheader {
    display: flex;
    font-size: .36rem;
    color: #78756A;
    align-items: flex-end;
    justify-content: space-between;
    height: .6rem;
    padding: 0 .32rem;

    img {
      width: 1.04rem;
      height: 10px;

      &:last-child {
        transform: rotateZ(180deg);
        // transform: rotateZ('angle');
      }
    }
  }
}



.cert_btns {
  display: flex;
  justify-content: space-between;
  padding: 0 .32rem;

  >div {
    width: 4.5867rem;
    height: 1.1333rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .3867rem;
    border-radius: .0533rem;
  }

  .active {
    background-color: #E61D18;
    color: #fff;
  }
  .disable {
    background-color: #eee;
    color: #666;
  }
}
</style>