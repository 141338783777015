// 这里根据移动端原生的 userAgent 来判断当前是 Android 还是 ios
const u = navigator.userAgent
// Android终端
const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
// IOS 终端
const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

/**
 * 配合 IOS 使用时的初始化方法
 */
const iosFunction = (callback) => {
	if (window.WebViewJavascriptBridge) { return callback(window.WebViewJavascriptBridge) }
	if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback) }
	window.WVJBCallbacks = [callback]
	var WVJBIframe = document.createElement('iframe')
	WVJBIframe.style.display = 'none'
	WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
	document.documentElement.appendChild(WVJBIframe)
	setTimeout(function() {
		document.documentElement.removeChild(WVJBIframe)
	}, 0)
}

/**
 * 配合 Android 使用时的初始化方法
 */
const androidFunction = (callback) => {
	if (window.WebViewJavascriptBridge) {
		callback(window.WebViewJavascriptBridge)
	} else {
		document.addEventListener('WebViewJavascriptBridgeReady', function() {
			callback(window.WebViewJavascriptBridge)
		}, false)
	}
}

window.setupWebViewJavascriptBridge = isAndroid ? androidFunction : isIOS ? iosFunction : androidFunction
window.setupWebViewJavascriptBridge(function(bridge) {

})

// js注册方法
export function appCallJsRegister(name, callBack) {
	window.setupWebViewJavascriptBridge(
		bridge => {
			bridge.registerHandler(name, callBack)
		}
	)
}

// js传递数据给java
export function jsToCallApp(data, callBack) {
	if (window.WebViewJavascriptBridge) {
		window.WebViewJavascriptBridge.callHandler(
			'h5CallApp',
			data,
			function(responseData) {
				// 回传的数据
				callBack && callBack(responseData)
			}
		)
	} else {
		callBack && callBack(-1)
	}
}
